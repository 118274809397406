import React from "react";
import { MapContainer, TileLayer } from "react-leaflet";
import L from "leaflet";

import { appURLs } from "@/app/constants.mjs";
import {
  AllLineMarkers,
  AllSpawnMarkers,
  LandmarkMarkers,
  NamedLocationMarkers,
} from "@/game-fortnite/components/InteractiveMapIcons";
import { DATA_VERSION } from "@/game-fortnite/constants/wiki-data.mjs";
import { useDynamicSettingsKeys } from "@/game-fortnite/utils/use-dynamic-settings-keys.mjs";
import { useQueryValues } from "@/util/router-hooks.mjs";

export default function Map() {
  const settingsKeys = useDynamicSettingsKeys();
  const toggles = useQueryValues(settingsKeys.map((x) => x[0]));

  return (
    <MapContainer
      style={{
        width: "100%",
        position: "absolute",
        inset: 0,
        zIndex: 0,
        margin: "auto",
        background: "#2F3135",
      }}
      center={L.latLng(-128, 128)}
      zoom={2}
      minZoom={1}
      maxZoom={6}
      scrollWheelZoom={true}
      zoomControl={false}
      attributionControl={false}
      crs={L.CRS.Simple}
    >
      <TileLayer
        url={`${appURLs.CDN_PLAIN}/blitz/fortnite/tiles/${DATA_VERSION}/{z}/{x}/{y}.webp`}
        noWrap
        bounds={[
          [0, 0],
          [-256, 256],
        ]}
      />
      {/* Negate the default value */}
      <AllSpawnMarkers toggles={toggles} />
      <AllLineMarkers toggles={toggles} />

      {Boolean(!toggles.namedLocations) && <NamedLocationMarkers />}
      {Boolean(toggles.landmarks) && <LandmarkMarkers />}
    </MapContainer>
  );
}
